import React from "react";
import {
  TextInput,
  Button,
  Container,
  Grid,
  Select,
  Modal,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import { MantineProvider, Menu } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import LabtestService from "../services/LabtestService";
import LabtestUpdate from "./LabTest2";
import "../style/dashboard.css";
import { showNotification } from "@mantine/notifications";
import StorageService from "../services/StorageService";
import { IconMoodSmile, IconLogout } from "@tabler/icons";
// import { Navigate } from "@tanstack/react-location";
import { useNavigate, Link } from "react-router-dom";
import profileMale from "../assets/male.png";
import { useEffect } from "react";
import { authCheck } from "../App";
const labtestService = new LabtestService();
const storageService = new StorageService();

function Dashboard() {
  let navigate = useNavigate();

  const [searchStatus, setSearchStatus] = React.useState<string>("1");
  const [countryCode, setCountryCode] = React.useState<string>("91");

  const [searchString, setSearchString] = React.useState<string>("");

  const [openLabtest, setLabtestOpen] = React.useState<boolean>(false);
  const [details, setDetails] = React.useState<any>([])
  const checkAuthSorage = () => {
    let result = false;
    let tok = storageService.get("token");
    if (tok === null) {
      result = true;
    }
    return result;
  };
  const [loading, setLoading] = React.useState<boolean>(checkAuthSorage());

  const [labInvestigation, setLabInvestigation] = React.useState<object>({});

  const [dataTables, setDataTables] = React.useState<object>({});

  const [logOut, setLogout] = React.useState<boolean>(false);

  useEffect(() => {
    // authCheck().then((e) => {
    if (logOut) {
      storageService.clearAll();
      window.location.reload();
      window.location.reload();

      // authCheck().then((e: any) => {
      //   <Link to="/login"></Link>;
      //   console.log("sferf", e);
      //   if (e) {
      //     navigate("/");
      //   } else {
      //     navigate("/login");
      //   }
      // });
    }
    // });
    console.log("dffd", logOut);
  }, [logOut]);
  const searchPatientLabtest = () => {
    let onResponse = (data: any) => {
      console.log("lab in", data);
      setLabInvestigation(data);
    };
    let onError = () => {
      setLoading(false);
    };

    labtestService.getPatient(searchString, onResponse, onError);
  };
  const getDataTables = () => {
    let onResponse = (data: any) => {
      setDataTables(data);
      console.log("datatabes", data);
    };
    let onError = () => {
      setLoading(false);
    };

    labtestService.getDataTables(onResponse, onError);
  };
  const searchPatient = () => {
    // getDataTables();
    // setTimeout(() => {
    //   setLabtestOpen(true);
    // }, 2000);
    let onResponse = (data: any) => {
      getDataTables();
      searchPatientLabtest();
      setTimeout(() => {
        setLoading(false);
        setLabtestOpen(true);
      }, 2000);
    };
    let onError = () => {
      setLoading(false);
      showNotification({
        title: "No Patient found",
        message: "",
        color: "red",
      });
    };

    labtestService.searchPatient(
      searchStatus,
      searchString,
      onResponse,
      onError
    );
  };

  interface DocumentDetails {
    documentId: string;
    appointmentId: string;
    serviceType: string;
    url: string;
  }

  const getDocumentByPid =(pid: any)=>{
    labtestService.getDocumentId(pid, 
      (res: any) => {
        const array: DocumentDetails[] = [];
        res.forEach((item: any) => {
          array.push({
            documentId: item.document.id,
            appointmentId: item.appointment.id,
            serviceType: item.service.type,
            url : item.document.url
          });
         
        });
        setDetails(array);
        searchPatient();
      },
      (err: any) => {
        if (err !== "No document id") { 
          const array=[];
          array.push({
            documentId: 'No document Id'
          })
        }
        searchPatient();
      })
  }

  const logoutBtn = () => {
    let phoneNo = storageService.get("phone_no");
    return (
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <div className="log-out-Button">
            <div style={{ alignSelf: "center" }}>
              <img
                className="profile-icon"
                width={"40px"}
                height={"40px"}
                src={profileMale}
                alt={"user profile"}
              />
              <label className="profile-label"> {phoneNo}</label>
              {/* <IconMoodSmile className="profile-icon"></IconMoodSmile>{" "} */}
            </div>
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            onClick={(e: any) => {
              setLoading(true);
              logout(e);
            }}
          >
            <IconLogout></IconLogout>{" "}
            <label className="icon-log-out">{"Log Out"}</label>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  };
  const logout = (e: any) => {
    e.preventDefault();
    storageService.clearAll();
    // setLoading(false);
    // navigate("/login");
    // window.location.reload();

    setTimeout(() => {
      // navigate("/login");
      setLogout(true);
    }, 1000);
    // window.location.reload();
  };

  return (
    <Container>
      {!logOut && (
        <Grid className="first-row-dashboard">
          <Grid.Col span={12}>
            <label className="dashboard-heading">
              <h1> Patients</h1>
            </label>
          </Grid.Col>
        </Grid>
      )}
      {!logOut && (
        <Grid className="search-bar-dashboard">
          <Grid.Col span={2}>
            <Select
              className="dropdown-dashboard"
              data={[
                { value: "1", label: "Id" },
                //   { value: "2", label: "Phone No" },
              ]}
              onChange={(val) => {
                let valstr = "";
                valstr = val as string;
                setSearchStatus(valstr);
              }}
              value={searchStatus}
            />
          </Grid.Col>
          {searchStatus === "2" && (
            <Grid.Col span={2}>
              <Select
                className="dropdown-dashboard"
                data={[
                  { value: "91", label: "India 91" },
                  { value: "97", label: "Dubai 97" },
                ]}
                onChange={(val) => {
                  let valstr = "";
                  valstr = val as string;
                  setCountryCode(valstr);
                }}
                value={countryCode}
              />
            </Grid.Col>
          )}

          <Grid.Col span={searchStatus === "1" ? 8 : 6}>
            <TextInput
              type="number"
              value={searchString}
              onChange={(val) => {
                let valstr = "";
                valstr = val.target.value as string;
                setSearchString(valstr);
              }}
              placeholder={
                searchStatus === "1"
                  ? "Type ID to search"
                  : "Seach Phone Number"
              }
            />
          </Grid.Col>
          <LoadingOverlay visible={loading} overlayBlur={2} />
          <Grid.Col span={2} className="padding-zero">
            <Button
              className="search-button"
              onClick={() => {
                getDocumentByPid(searchString);
                setLoading(true);
                //searchPatient();
              }}
            >
              SEARCH
            </Button>
          </Grid.Col>
        </Grid>
      )}
      {!logOut && (
        <Grid>
          <Grid.Col span={2} className="padding-zero">
            {logoutBtn()}
          </Grid.Col>
        </Grid>
      )}
      {logOut && (
        <Grid className="first-row-dashboard" style={{ textAlign: "center" }}>
          <Grid.Col span={12}>
            <label className="dashboard-heading">
              <h1> Logging Out..</h1>
            </label>
          </Grid.Col>
        </Grid>
      )}
      {openLabtest && (
        <LabtestUpdate
          labtest={labInvestigation}
          dataTables={dataTables}
          patientId={searchString}
          details={details}
          close={() => setLabtestOpen(false)}
        />
      )}
    </Container>
  );
}

export default Dashboard;
