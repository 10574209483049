import "./App.css";
import React, { useEffect, useState } from "react";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { useNavigate, Routes, Route, BrowserRouter } from "react-router-dom";
import StorageService from "./services/StorageService";
import Logout from "./components/Logout";
const storageService = new StorageService();

export const authCheck = async (): Promise<boolean> => {
  let token = storageService.get("token");
  // WithNavigate()

  console.log("authCheck", token, token !== null);
  // token === null ? WithNavigate("/") : WithNavigate("/dashboard");
  return token !== null;
};
// export const NavigatePath = (path: string) => {
//   const navigate = useNavigate();

//   return navigate(path);
// };
const Protected = ({ path, isLoggedIn, children, redirect }: any) => {
  const navigate = useNavigate();
  isLoggedIn.then((b: any) => {
    if (!b) {
      navigate(redirect);
    } else {
      navigate(path);
    }
  });

  return children;
};

// const ProtectedRoute = ({
//   component,
// }: {
//   component: React.ReactElement;
// }): JSX.Element => {
//   const [authenticated, setAuthenticated] = useState<Boolean>(true);
//   useEffect(() => {
//     check().then((b) => {
//       setAuthenticated(b);
//       if (!b) {
//         // LogoutUser();
//         storageService.clearAll();
//       }
//     });
//   }, []);
//   return <>{authenticated ? component : <Navigate to="/" />}</>;
// };

// const UnprotectedRoute = ({
//   component,
// }: {
//   component: React.ReactElement;
// }): JSX.Element => {
//   const [authenticated, setAuthenticated] = useState<Boolean>(false);
//   const [loading, setLoading] = useState(false);
//   useEffect(() => {
//     setLoading(true);
//     check().then((b) => {
//       setAuthenticated(b);
//       setLoading(false);
//     });
//   }, []);
//   return loading ? (
//     <> </>
//   ) : (
//     <>{!authenticated ? component : <Navigate to="/login" />}</>
//   );
// };

function App() {
  // const reactLocation = new ReactLocation();
  // const routes: Route[] = [
  //   { path: "login", element: <UnprotectedRoute component={<Login />} /> },
  //   {
  //     path: "/",
  //     element: <ProtectedRoute component={<Dashboard />} />,
  //   },
  // ];
  // return (
  // <div>
  //   <Router routes={routes} location={reactLocation}></Router>
  // </div>
  // );
  // const [isLoggedIn, setisLoggedIn] = useState<boolean>(false);
  // const logIn = () => {
  //   setisLoggedIn(true);
  // };
  // const logOut = () => {
  //   setisLoggedIn(false);
  // };
  useEffect(() => {
    console.log("ytayayay");
  }, []);
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />

          <Route
            path="/"
            element={
              <Protected path="/" redirect="/login" isLoggedIn={authCheck()}>
                <MantineProvider withNormalizeCSS withGlobalStyles>
                  <NotificationsProvider>
                    <Dashboard />
                  </NotificationsProvider>
                </MantineProvider>
              </Protected>
            }
          ></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
