class ApiService {
  constructor() {}

  get(url, header, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then(function (response) {
        if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response

        response
          .json()
          .then(function (data) {
            onResponse(data);
            return;
          })
          .catch((err) => {});
      })
      .catch(function (error) {
        onError(error);
      });
  }

  post(url, header, params, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "POST",
      headers: header,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        onError(error);

        return;
      });
  }
  put(url, header, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PUT",
      headers: header,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message:
                  error && error.message !== undefined && error.message
                    ? error.message
                    : "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        onError({
          message:
            error && error.message !== undefined && error.message
              ? error.message
              : "Looks like something went wrong. Please try again.",
        });
      });
  }
}

export default ApiService;
