import React, { useEffect, useState } from "react";
import success from "../assets/success.png";
import { Row, Col } from "react-flexbox-grid";
import {
  Modal,
  Autocomplete,
  Button,
  Card,
  Select,
  Radio,
  TextInput,
  Textarea,
  Menu,
  LoadingOverlay,
} from "@mantine/core";
// import { Autocomplete } from "react-md";
import { IconCircleMinus, IconMinus, IconPlus } from "@tabler/icons";
import "../style/LabtestTracking.css";
import LabtestService from "../services/LabtestService";
import { showNotification } from "@mantine/notifications";
import ChooseScreen from "./ChooseScreen";
import IconReview from "../assets/review2.png";
import { useSetState } from "@mantine/hooks";
import cloneDeep from "lodash/cloneDeep";
// import { CodeValuePair } from "../model/Login";

const dummyLabtest = {
  dateCreated: new Date().getTime(),
      //   createdBy: null,
      //   createdByName: null,
      labTest: null,
      labTestCode: {
        label: "",
        value: "",
      },
      dayAssessment: null,
      testConductedDate: ``,
      monthAssessment: null,
      yearAssessment: null,
      evidenceOfReports: null,
      allSubTestAssessed: null,
      range: null,
      subTest: [
        {
          subTestCode: { label: "", value: "" },
          subTest: null,
          loincCode: ``,
          value: ``,
          valueUOM: null,
          range: null,
        },
      ],
      description: ``,
}

function LabtestUpdate(props) {
  // console.log("labtest new", props);
  const labtestService = new LabtestService();
  const [successFlag, setsuccessFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  const [rerender, setRerender] = useState(true);
  const [screen, setScreen] = useState("");
  const [status, setStatus] = useState("new"); //review
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [details, setDetails] = useState(props.details);
  const [detail, setDetail] = useState(
    props.details.length > 0 ? props.details[0] : null
  );
  const [serviceType, setServiceType]= useState("");
  const [getTracking, setGetTracking]= useState(false);
  const [trackingId, setTrackingId] = useState("");

    useEffect(()=>{addMore()},[])
  // status: "new",
  const setLabInvestigationMap = (props) => {
    // console.log("props props", props);
    let labInvestigations = {};
    // if (props.labtest) {
    if (props.labtest.labtest !== null) {
      props.labtest.labtest.map((labtest) => {
        labtest.yearAssessment = null;

        labtest.monthAssessment = null;
        labtest.dayAssessment = null;
        if (labtest.subTest.length === 0) {
          labtest.subTest.push({
            subTest: null,
            loincCode: null,
            value: null,
            valueUOM: null,
            range: null,
          });
        }
      });

      labInvestigations = props.labtest;
    } else {
      labInvestigations = {
        patientId: null,
        createdBy: null,
        createdByName: null,

        review: null,
        labtest: [
          {
            // dateCreated: null,
            //   createdBy: null,
            //   createdByName: null,
            labTest: null,
            labTestCode: {
              label: "",
              value: "",
            },
            dateCreated: new Date().getTime(),
            dayAssessment: null,
            testConductedDate: ``,
            monthAssessment: null,
            yearAssessment: null,
            evidenceOfReports: null,
            allSubTestAssessed: null,
            range: null,
            subTest: [
              {
                subTest: null,
                subTestCode: { label: "", value: "" },
                loincCode: null,
                value: null,
                valueUOM: null,
                range: null,
              },
            ],
            description: null,
          },
        ],
      };
    }

    return labInvestigations;
  };
  const [labInvestigations, setLabInvestigations] = useState({});

  const [labTestData, setLabtestData] = useState([]);
  const [sublabTestData, setSublabTestData] = useState([]);

  const [patientId, setPatientId] = useState(props.patientId || null);
  const [dataTables, setDataTables] = useState(props.dataTables || {});
  const [range, setRange] = useState(
    props.dataTables.LAB_TEST_RANGE !== undefined
      ? props.dataTables.LAB_TEST_RANGE
      : [] || []
  );
  const [evidence, setEvidence] = useState(
    props.dataTables.LAB_TEST_REPORT_EVIDENCE !== undefined
      ? props.dataTables.LAB_TEST_REPORT_EVIDENCE
      : [] || []
  );
  const [subTestRange, setsubTestRange] = useState(
    props.dataTables.SUB_LAB_TEST_RANGE || []
  );
  const [testUOM, settestUOM] = useState(
    props.dataTables.LAB_TEST_UOM !== undefined
      ? props.dataTables.LAB_TEST_UOM
      : [] || []
  );

  // labTestData  // labInvestigations: null, // setLabInvestigation(props),

  const addMore = () => {
    let labInvestigation = labInvestigations.labtest? labInvestigations.labtest : [];
    labInvestigation?.unshift({
      dateCreated: new Date().getTime(),
      //   createdBy: null,
      //   createdByName: null,
      labTest: null,
      labTestCode: {
        label: "",
        value: "",
      },
      dayAssessment: null,
      testConductedDate: ``,
      monthAssessment: null,
      yearAssessment: null,
      evidenceOfReports: null,
      allSubTestAssessed: null,
      range: null,
      subTest: [
        {
          subTestCode: { label: "", value: "" },
          subTest: null,
          loincCode: ``,
          value: ``,
          valueUOM: null,
          range: null,
        },
      ],
      description: ``,
    });
    labInvestigations.labtest = labInvestigation;
    setLabInvestigations(labInvestigations);
    setRerender(false);
    setRerender(true);
    console.log("dsd", labInvestigations);
  };
  const selectSave = (review) => {
    console.log("aryan", detail.documentId, detail.appointmentId);
    var lab = labInvestigations;
    lab.documentId =
      detail && typeof detail.documentId === "number"
        ? detail.documentId
        : null;
    lab.appointmentId =
      detail && typeof detail.appointmentId === "number"
        ? detail.appointmentId
        : null;
    let errorFlag = false;
    console.log("dfgrrdfearfehfneriu", lab.labtest);
    lab.labtest.map((e) => {
      if (e.labTestCode.value === null || e.labTestCode.value === "") {
        errorFlag = true;
        showNotification({
          title: "Error in saving!",
          message: "Check lab test name",
          color: "red",
        });
        // labTestErrorFlag = true;
      } else {
        errorFlag = false;
        if (e.testConductedDate === null) {
          errorFlag = true;
          showNotification({
            title: "Error in saving!",
            message: "Check date ",
            color: "red",
          });
        } else {
          errorFlag = false;
          if (e.subTest.length > 0) {
            e.subTest.map((eSub) => {
              if (
                eSub.subTestCode?.value === null ||
                eSub.subTestCode?.value === ""
              ) {
                errorFlag = true;
                showNotification({
                  title: "Error in saving!",
                  message: "Check sub tests name ",
                  color: "red",
                });
              } else {
                errorFlag = false;
              }
            });
          } else {
            errorFlag = true;
            showNotification({
              title: "Error in saving!",
              message: "Check sub tests added ",
              color: "red",
            });
          }
        }
      }
    });

    setTimeout(() => {
      if (!errorFlag) {
        if (review) {
          if(getTracking){
          console.log("inside update if");
          setLoading(true);
          saveLabUpdate(lab, review);
          }
          else {
            setLoading(true);
            saveLabNew(lab, review);
          }
        } else {
          if(getTracking){
          console.log("inside new if");  
          setLoading(true);
          saveLabUpdate(lab, review);
          }
          else{
            console.log("inside update else");
            setLoading(true);
            saveLabNew(lab, review);
          }
        }
      } else {
        // showNotification({
        //   title: "Check Error!",
        //   message: "Check Error",
        //   color: "red",
        // });
      }
    }, 1500);
  };

  const saveLabNew = (labInvestigation, review) => {
    labInvestigation.createdBy = 156054;
    labInvestigation.createdByName = "Vikrant Kumar";
    labInvestigation.createdBy = 156054;
    labInvestigation.patientId = parseInt(patientId);
    labInvestigation.review = review;
    labInvestigation.updatedBy = 156054;
    labInvestigation.updatedByName = "Vikrant Kumar";

    delete labInvestigations.id;
    delete labInvestigations.dateCreated;
    delete labInvestigations.dateUpdated;
    console.log("abc", labInvestigation);
    labtestService.addLabTest(
      labInvestigation,
      patientId,
      (data) => {
        setsuccessFlag(true);
        setLoading(false);
        setTimeout(() => {
          this.props.close();
          setsuccessFlag(false);
        }, 3000);
      },
      (error) => {
        setLoading(false);
        showNotification({
          title: "Error in saving!",
          message: "Check date",
          color: "red",
        });
      }
    );
  };
  const saveLabUpdate = (labInvestigation, review) => {
    labInvestigation.createdBy = 156054;
    labInvestigation.createdByName = "Vikrant Kumar";
    labInvestigation.createdBy = 156054;
    labInvestigation.patientId = parseInt(patientId);
    labInvestigation.review = review;
    labInvestigation.updatedBy = 156054;
    labInvestigation.updatedByName = "Vikrant Kumar";
    labInvestigation.id = trackingId;
    console.log("lab test is", labInvestigation);
    labtestService.updateLabTest(
      labInvestigation,
      patientId,
      labInvestigation.id,
      (data) => {
        setsuccessFlag(true);
        setLoading(false);
        setTimeout(() => {
          this.props.close();
          setsuccessFlag(false);
        }, 3000);
      },
      (error) => {
        setLoading(false);
        showNotification({
          title: "Error in saving!",
          message: "Check date",
          color: "red",
        });
      }
    );
    // labtestService.addLabTest(
    //   labInvestigation,
    //   patientId,
    //   (data) => {
    //     setsuccessFlag(true);
    //     setTimeout(() => {
    //       this.props.close();
    //       setsuccessFlag(false);
    //     }, 3000);
    //   },
    //   (error) => {}
    // );
  };
  const fire = (text, index) => {
    // let labInvestigations = labInvestigations;

    let str = text.substring(0, 4);

    if (str === "LABT") {
      // const { labTestData } = state;
      let item = labTestData.find((e) => e.value === text);

      if (item !== undefined) {
        labInvestigations.labtest[index].labTest = text;
        labInvestigations.labtest[index].labTestCode = {
          label: item.label,
          value: text,
        };
        // OnSelect(text, index);
      }
      setLabInvestigations(labInvestigations);
    } else {
      labInvestigations.labtest[index].labTestCode = { label: text, value: "" };
      setLabInvestigations(labInvestigations);
      // setState({ labInvestigations }, () => {
      //   // setState({ rerenderFlag: true });
      if (text.length > 1) {
        getLabTest(text);
      }
      // });
    }
    //
  };

  const getLabTest = (text) => {
    let onResponse = (data) => {
      if (data) {
        setLabtestData(data);
      }
    };
    let onError = (error) => {};

    labtestService.getLabTest(text, onResponse, onError);
  };

  const setEvidenceReports = () => {
    let evidenceReport = "";
    if (detail) {
      switch (detail.documentId) {
        case "User informed verbally":
          evidenceReport = "REFLD149";
          break;
        case "User informed via app":
          evidenceReport = "REFLD835";
          break;
        case "None":
          evidenceReport = "REFLD148";
          break;
        default:
          evidenceReport = "REFLD150";
      }
    }
    let labInvestiation = labInvestigations.labtest;
    labInvestiation.forEach((x) => {
      x.evidenceOfReports = evidenceReport;
    });
  };

  // subTest: [
  //   {
  //     subTestCode: { label: "", value: "" },
  //     subTest: null,
  //     loincCode: ``,
  //     value: ``,
  //     valueUOM: null,
  //     range: null,
  //   },
  // ],

  const getAllTracking = (pid, documentId) => {
    const payload = {
      documentId: documentId,
    };
  
    labtestService.getAllTracking(
      pid,
      payload,
      (res) => {
        console.log("response of api is", res);
        setOpenDialog(true);
        setOpenModal(false);
        let data = [{...dummyLabtest}]
        if (res.length> 0){
          setGetTracking(res[0].review);
          setTrackingId(res[0].id);
          data = res[0].labtest
          data.forEach((e)=>{
            if(e.subTest.length==0){
              e.subTest = [
                {
                  subTestCode: { label: "", value: "" },
                  subTest: null,
                  loincCode: ``,
                  value: ``,
                  valueUOM: null,
                  range: null,
                },
              ]
            }
          })
        }
        labInvestigations.labtest = data
        setLabInvestigations(labInvestigations);        
      },
      (err) => {
        console.log("no response found", err);
        setOpenDialog(true);
        setOpenModal(false);
      }
    );
  };

  // useEffect(() => {
  //   console.log("aryan", labInvestigations);
  // }, [labInvestigations]);

  const removeLabtestCard = (index) => {
    // var labInvestigations = labInvestigations; //labtest;
    if (labInvestigations.labtest.length > 1) {
      labInvestigations.labtest.splice(index, 1);

      setLabInvestigations(labInvestigations);
      setRerender(false);
      setRerender(true);
      // setState({ labInvestigations, rerenderFlag: false }, () => {
      //   setState({
      //     rerenderFlag: true,
      //     editFlag: true,
      //   });
      // });
    } else {
      showNotification({
        title: "Error removing!",
        message: "Minimum 1 lab test required 🤥",
        color: "red",
      });
    }
  };

  const addMoreSubTest = (index) => {
    // let labInvestigations = labInvestigations;
    labInvestigations.labtest[index].subTest.push({
      subTestCode: { label: "", value: "" },
      subTest: null,
      loincCode: ``,
      value: ``,
      valueUOM: null,
      range: null,
    });
    setLabInvestigations(labInvestigations);
    setRerender(false);
    setRerender(true);
    // setState({
    //   labInvestigations,
    //   editFlag: true,
    // });
  };
  const deleteSubTest = (index, indexSubTest) => {
    // let labInvestigations = labInvestigations;
    if (labInvestigations.labtest[index].subTest.length > 1) {
      labInvestigations.labtest[index].subTest.splice(indexSubTest, 1);
      // setState({
      //   labInvestigations,
      //   editFlag: true,
      // });
      setLabInvestigations(labInvestigations);
      setRerender(false);
      setRerender(true);
    } else {
      showNotification({
        title: "Error removing!",
        message: "Minimum 1 sub test required 🤥",
        color: "red",
      });
    }
  };

  const addSubTestButton = (index, indexSubTest) => {
    return (
      <div className="subtest-buttons">
        <IconCircleMinus
          className="remove-sub-labtest"
          style={{ top: "1rem", padding: "3px", marginRight: "10px" }}
          onClick={() => deleteSubTest(index, indexSubTest)}
        />
        <IconPlus
          className="add-sub-labtest"
          style={{ top: "1rem" }}
          swapTheming
          onClick={() => addMoreSubTest(index)}
        />
      </div>
    );
  };

  const fireSub = (text, index, subIndex, labTest) => {
    let str = text.substring(0, 5);

    if (str === "SLABT") {
      let item = sublabTestData.find((e) => e.value === text);

      if (item !== undefined) {
        labInvestigations.labtest[index].subTest[subIndex].subTest = text;
        labInvestigations.labtest[index].subTest[subIndex].subTestCode = {
          label: item.label,
          value: text,
        };
        onSubtestSelect(labTest, text, index, subIndex);
      }
      setLabInvestigations(labInvestigations);
      // setState({ labInvestigations }, () => {});
    } else {
      labInvestigations.labtest[index].subTest[subIndex].subTestCode = {
        label: text,
        value: "",
      };
      setLabInvestigations(labInvestigations);
      // setState({ labInvestigations }, () => {
      //   // setState({ rerenderFlag: true });
      if (text.length > 1) {
        getLabTestSub(text, labTest);
      }
      // });
    }
  };
  const onSubtestSelect = (labtest, subtest, index, subIndex) => {
    let onResponse = (data) => {
      var event = { nativeEvent: { target: { name: "LOINCname" } } };
      changeSubTestFields(data.loincName, event, index, subIndex);
    };
    let onError = (error) => {};
    labtestService.getSubTestData(labtest, subtest, onResponse, onError);
  };
  const getLabTestSub = (text, Id) => {
    let onResponse = (data) => {
      if (data) setSublabTestData(data);
    };
    let onError = (error) => {};

    labtestService.getSubTest(Id, text, onResponse, onError);
  };
  const changeFieldsInLabInvestigations = (value, proxy, index) => {
    let event = proxy.nativeEvent;
    // let labInvestigations = labInvestigations;
    let labInvestigations2 = cloneDeep(labInvestigations);
    let lab = labInvestigations2.labtest[index];
    // lab.dateCreated = new Date().getTime();
    // lab.createdBy = "67282"; //props.userProfile.id;
    // lab.createdByName = "nimit";
    // props.userProfile.employeeProfile.firstName +
    // " " +
    // props.userProfile.employeeProfile.lastName;
    switch (event.target.name) {
      case "labTestName":
        lab.labtestName = value;

        break;
      case "labTest":
        lab.labTest = value;

        break;
      case "description":
        lab.description = value;

        break;
      case "date1":
        lab.dayAssessment = value;

        break;
      case "month1":
        lab.monthAssessment = value;

        break;
      case "year1":
        lab.yearAssessment = parseInt(value);

        break;
      case "testConductedDate":
        lab.testConductedDate = value;

        break;
      default:
        break;
    }
    labInvestigations2.labtest[index] = lab;
    setLabInvestigations(labInvestigations2);
    // setState({ labInvestigations, editFlag: true });
  };

  const subtestValue = (value, index, subTestindex) => {
    // let labInvestigations = labInvestigations;
    let lab = labInvestigations.labtest[index].subTest[subTestindex];
    let labtest = labInvestigations.labtest[index];
    // labtest.dateCreated = new Date().getTime();
    // labtest.createdBy = "67282"; //props.userProfile.id;
    // labtest.createdByName = "e";
    // props.userProfile.employeeProfile.firstName +
    // " " +
    // props.userProfile.employeeProfile.lastName;
    lab.valueUOM = value;
    labInvestigations.labtest[index].subTest[subTestindex] = lab;
    setLabInvestigations(labInvestigations);
    // setState({
    //   labInvestigations,
    //   editFlag: true,
    // });
  };

  const assessmentofReport = (value, index) => {
    // let labInvestigations = labInvestigations;
    let lab = labInvestigations.labtest[index];
    // lab.dateCreated = new Date().getTime();
    // lab.createdBy = "67282"; //props.userProfile.id;
    // lab.createdByName = "nimit";
    // props.userProfile.employeeProfile.firstName +
    // " " +
    // props.userProfile.employeeProfile.lastName;
    if (value) lab.allSubTestAssessed = true;
    else if (value === false) lab.allSubTestAssessed = false;
    else lab.allSubTestAssessed = null;
    labInvestigations.labtest[index] = lab;
    setLabInvestigations(labInvestigations);
    // setState({
    //   labInvestigations,
    //   editFlag: true,
    // });
  };
  const selectLabRange = (value, index) => {
    // let labInvestigations = labInvestigations;
    let lab = labInvestigations.labtest[index];
    lab.range = value;
    // lab.dateCreated = new Date().getTime();
    // lab.createdBy = "67282"; //props.userProfile.id;
    // lab.createdByName = "nimit";
    // props.userProfile.employeeProfile.firstName +
    // " " +
    // props.userProfile.employeeProfile.lastName;
    labInvestigations.labtest[index] = lab;
    setLabInvestigations(labInvestigations);
    // setState({
    //   labInvestigations,
    //   editFlag: true,
    // });
  };
  const changesubTestRange = (value, index, subTestindex) => {
    // let labInvestigations = labInvestigations;
    let lab = labInvestigations.labtest[index].subTest[subTestindex];
    let labtest = labInvestigations.labtest[index];
    // labtest.dateCreated = new Date().getTime();
    // labtest.createdBy = "67292"; //props.userProfile.id;
    // labtest.createdByName = "uih";
    // props.userProfile.employeeProfile.firstName +
    // " " +
    // props.userProfile.employeeProfile.lastName;
    lab.range = value;
    labInvestigations.labtest[index].subTest[subTestindex] = lab;
    setLabInvestigations(labInvestigations);
  };
  const changeSubTestFields = (value, proxy, index, indexSubTest) => {
    let event = proxy.nativeEvent;

    console.log(
      "change oelds subtest",
      value,
      event.target.name,
      index,
      indexSubTest
    );
    // let labInvestigations = labInvestigations;
    let lab = labInvestigations.labtest[index].subTest[indexSubTest];
    let labtest = labInvestigations.labtest[index];

    // labtest.dateCreated = new Date().getTime();
    // labtest.createdBy = "67282"; //props.userProfile.id;
    // labtest.createdByName = "erf";
    // props.userProfile.employeeProfile.firstName +
    // " " +
    // props.userProfile.employeeProfile.lastName;
    switch (event.target.name) {
      case "subTestName":
        lab.subTestName = value;

        break;
      case "subTest":
        lab.subTest = value;

        break;
      case "LOINCname":
        lab.loincCode = value;

        break;
      case "value":
        lab.value = value;

        break;
      default:
        break;
    }
    labInvestigations.labtest[index].subTest[indexSubTest] = lab;

    setLabInvestigations(labInvestigations);
    console.log("change fields", labInvestigations);
    // setState({
    //   labInvestigations,
    //   editFlag: true,
    // });
  };
  const testConduct = (value) => {
    if (value) {
      var year = new Date(value).getFullYear().toString();
      var month = (new Date(value).getMonth() + 1).toString();
      if (month.length === 1) month = "0" + month;
      var date = new Date(value).getDate().toString();
      if (date.length === 1) date = "0" + date;

      return year + "-" + month + "-" + date;
    } else {
      return ``;
    }
  };

  const select = (val) => {
    console.log("ssss", val);
    setScreen("2");
    // setState({
    //   screen: "2",
    //   status: val,
    // });
    // if (val === "review") {
    //   searchPatientLabtest();
    // }
  };
  const searchPatientLabtest = () => {
    let onResponse = (data) => {
      //   setLabInvestigation(data);
      console.log("akakakak", data);
      if (data.labtest !== undefined) {
        setLabInvestigations(data);
        // setState({
        //   labInvestigations: data,
        // });
      }
    };
    let onError = () => {};

    labtestService.getPatient(patientId, onResponse, onError);
  };
  const onDocumentIdUpdate = (e) => {
    const documentId = e.target.value;
    const selectedDocument = details.find((x) => x.documentId == documentId);
    setDetail(selectedDocument);
  
    let serviceType=""
    if (!isNaN(documentId)) {
      serviceType = selectedDocument ? selectedDocument.serviceType : "";
    }
    setServiceType(serviceType);

  };
  const viewDocument = () => {
    const url = detail ? detail.url : "";
    if (url) {
      window.open(url, "_blank");
    }
  };
  return (
    <div>
      <Modal
        opened={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div>
          <div className="modal">
            <h2>
              {"Lab Investigations"}
            </h2>
            <p className="info-line">
              *Please select the document ID carefully. Once tracking data has
              been entered, but not saved, all entered data will be lost.
            </p>
            <div className="modal-content">
              <div className="doc-drop-down">
                <label htmlFor="documentId">Document ID*</label>
                <select id="documentId" onChange={onDocumentIdUpdate}>
                <option value="" disabled selected>Select Document ID</option>
                  {details &&
                    details.map((item) => (
                      <option value={item.documentId}>{item.documentId}</option>
                    ))}
                </select>
              </div>
              <div className="service-type">
                <label htmlFor="serviceType">Service Type</label>
                <input
                  id="serviceType"
                  className="service-type-field"
                  type="text"
                  value={serviceType}
                  disabled
                />
              </div>
              {/* <button className="modal-close-button" onClick={() => setOpenModal(false)}>X</button> */}
            </div>
            <div className="modal-buttons">
              <button className="view-doc-button" onClick={viewDocument}>
                View document
              </button>
              <button
                className="confirm-doc-button"
                onClick={() => {
                  getAllTracking(props.patientId, detail.documentId);
                  setEvidenceReports();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        opened={openDialog}
        withCloseButton={false}
        onClose={() => props.close()}
        className="labtest-container"
        title={
          !successFlag && (
            <div>
              <div className="title-dialog">
                <div>
                  <h3 style={{ margin: "0" }}>
                    {"Lab Investigations (" +
                      (labInvestigations &&
                        labInvestigations.labtest !== undefined &&
                        labInvestigations.labtest.length) +
                      ")"}
                  </h3>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      addMore();
                    }}
                    disabled={loading}
                    className="add-labtest"
                  >
                    + Add new
                  </Button>
                  {/* {labInvestigations.review&&} */}
                  <Button
                    disabled={loading}
                    // onClick={() => {
                    //   addMore();
                    // }}
                    className="add-labtest"
                    onClick={() => {
                      selectSave(true);
                    }}
                    style={{ background: "#ef7c01" }}
                  >
                    Save for review
                  </Button>

                  <Button
                    disabled={loading}
                    // onClick={() => {
                    //   addMore();
                    // }}
                    className="add-labtest"
                    onClick={() => {
                      selectSave(false);
                    }}
                    style={{ background: "#ef7c01" }}
                  >
                    Save
                  </Button>

                  {/* <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button style={{ background: "#ef7c01" }}>Save</Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item>
                  <label
                    onClick={() => {
                      selectSave(false);
                    }}
                  >
                    Save
                  </label>{" "}
                </Menu.Item>
                <Menu.Item>
                  <label
                    onClick={() => {
                      selectSave(true);
                    }}
                  >
                    Save For Review
                  </label>{" "}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu> */}
                </div>
              </div>
              <div className="modal-content">
                <div className="doc-drop-down">
                  <label htmlFor="documentId">Document ID*</label>
                  <input
                    id="documentId"
                    className="drop-down-menu"
                    value={detail ? detail.documentId : ""}
                    disabled
                  ></input>
                </div>
                <div className="service-type">
                  <label htmlFor="serviceType">Service Type</label>
                  <input
                    id="serviceType"
                    className="service-type-field"
                    type="text"
                    value={detail ? detail.serviceType : ""}
                    disabled
                  />
                </div>
              </div>
              <button className="view-doc-button" onClick={viewDocument}>
                View document
              </button>
            </div>
          )
        }
      >
        <button
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "-75px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  width: "53px",
                  height: "53px",
                  borderRadius: "70%",
                  backgroundColor: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#333",
                  fontWeight: "bold",
                  fontSize: "17px",
                }}
                onClick={props.close}
              >
                X
              </button>
        <div id="Lab Investigations">
          {loading && <LoadingOverlay visible={loading} overlayBlur={2} />}
          {successFlag && screen === "" && (
            <Col xs={12} sm={12} md={12} lg={12}>
              <Row className="center-success">
                <img className="image-labtest-success" src={success} />
              </Row>
              <Row className="center-success">
                <h2>Entry created successfully</h2>
              </Row>
            </Col>
          )}

          {!successFlag &&
            screen === "" &&
            labInvestigations &&
            labInvestigations.labtest?.map((test, index) => (
              <div key={index + "LabInv"}>
                {test.review && (
                  <img className="review-icon" src={IconReview}></img>
                )}
                <Card
                  key={index + "labtest"}
                  className="md-block-centered container-card-lab-test"
                >
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Row>
                      <Col xs={5} className="eachrow">
                        <Autocomplete
                          withAsterisk
                          label="Lab Test"
                          placeholder="Type here..."
                          data={labTestData}
                          onChange={(e) => {
                            fire(e, index);
                          }}
                          value={test?.labTestCode?.label}
                          filter={(value, item) =>
                            item.label
                              .toLowerCase()
                              .includes(value.toLowerCase().trim())
                          }
                        />
                      </Col>
                      <Col xs={3} className="eachrow">
                        <Row className="labelHeading ">
                          Date <label style={{ color: "#fa5252" }}>*</label>{" "}
                        </Row>
                        <Row>
                          <input
                            style={{ border: "none" }}
                            type="date"
                            key={"testConductedDate" + index}
                            id={"testConductedDate" + index}
                            name="testConductedDate"
                            value={testConduct(test.testConductedDate)}
                            max={new Date().toISOString().split("T")[0]}
                            onChange={(e) => {

                              var event = {
                                nativeEvent: {
                                  target: e.target,
                                },
                              };
                              changeFieldsInLabInvestigations(
                                new Date(
                                  new Date(e.target.value).getFullYear(),
                                  new Date(e.target.value).getMonth(),
                                  new Date(e.target.value).getDate()
                                ).getTime(),
                                event,
                                index
                              );
                            }}
                          />
                        </Row>
                      </Col>

                      <Col xs={3} className="eachrow"></Col>
                      <Col xs={1}>
                        <Row>
                          <IconMinus
                            style={{
                              position: "relative",
                              right: "-2rem",
                            }}
                            onClick={() => {
                              removeLabtestCard(index);
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={5} className="eachrow row-2">
                        <Row className="labelHeading leftSpace">
                          Allsub tests assessed{" "}
                        </Row>
                        <Row
                          id={"subTestAssessed" + index}
                          className="each-row-2"
                        >
                          {[
                            {
                              label: "Yes",
                              value: true,
                            },
                            {
                              label: "No",
                              value: false,
                            },
                            { label: "None", value: null },
                          ].map((itm) => (
                            <Radio
                              className="each-radio"
                              name={"subTestAssessed_" + index}
                              checked={itm.value === test.allSubTestAssessed}
                              label={itm.label}
                              onClick={() => {
                                console.log("allevifididi", itm.value);
                                assessmentofReport(itm.value, index);
                              }}
                            />
                          ))}
                        </Row>
                      </Col>
                      <Col xs={7} className="eachrow">
                        <Row className="labelHeading ">Range</Row>
                        <Row className="each-row-2">
                          {range !== undefined &&
                            range &&
                            range.map((itm) => (
                              <Radio
                                className="each-radio"
                                checked={itm.value === test.range}
                                label={itm.label}
                                onClick={() => {
                                  selectLabRange(itm.value, index);
                                }}
                              />
                            ))}
                        </Row>
                      </Col>
                    </Row>

                    {/* ..........;;;;;;;;;; */}
                    {test.subTest.map((subTest, indexSubTest) => {
                      return (
                        <div key={indexSubTest + "subtest"}>
                          <Row>
                            <Col
                              xs={7}
                              className="eachrow"
                              // style={{ display: "flex" }}
                            >
                              <Autocomplete
                                withAsterisk
                                label="Sub Lab Test"
                                placeholder="Type here..."
                                data={sublabTestData}
                                onChange={(e) => {
                                  fireSub(e, index, indexSubTest, test.labTest);
                                }}
                                value={
                                  subTest.subTestCode !== undefined
                                    ? subTest.subTestCode.label
                                    : subTest.subTest
                                }
                                filter={(value, item) =>
                                  item.label
                                    .toLowerCase()
                                    .includes(value.toLowerCase().trim())
                                }
                              />
                            </Col>
                            <Col
                              xs={3}
                              className="eachrow"
                              style={{ display: "flex" }}
                            >
                              {" "}
                              {addSubTestButton(index, indexSubTest)}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={5}>
                              {rerender && (
                                <TextInput
                                  key={"loincname" + indexSubTest + index}
                                  label="Loinc Name"
                                  name="LOINCname"
                                  type="text"
                                  id={"loincname" + indexSubTest + index}
                                  onChange={(value) => {
                                    var event = {
                                      nativeEvent: {
                                        target: { name: "LOINCname" },
                                      },
                                    };
                                    changeSubTestFields(
                                      value.target.value,
                                      event,
                                      index,
                                      indexSubTest
                                    );
                                  }}
                                  value={subTest.loincCode}
                                />
                              )}
                            </Col>
                            <Col xs={2}>
                              {rerender && (
                                <TextInput
                                  key={"value" + indexSubTest + index}
                                  label="Value"
                                  name="value"
                                  type="number"
                                  id={"value" + indexSubTest + index}
                                  onChange={(value) => {
                                    // if (value.length < 11) {
                                    var event = {
                                      nativeEvent: {
                                        target: { name: "value" },
                                      },
                                    };
                                    changeSubTestFields(
                                      value.target.value,
                                      event,
                                      index,
                                      indexSubTest
                                    );
                                    // }
                                  }}
                                  value={subTest.value}
                                />
                              )}
                            </Col>
                            <Col xs={2}>
                              <Select
                                label="Unit"
                                // style={{ width: "70%" }}
                                value={subTest.valueUOM}
                                className="dropdown-dashboard"
                                data={testUOM}
                                id={"valueUom" + indexSubTest + index}
                                onChange={(value, e) =>
                                  subtestValue(value, index, indexSubTest)
                                }
                              />
                            </Col>
                            <Col xs={3}>
                              <Select
                                label="Range"
                                style={{ width: "70%" }}
                                value={subTest.range}
                                className="dropdown-dashboard"
                                data={subTestRange}
                                name="range-subtest"
                                id={"range-subtest" + indexSubTest + index}
                                onChange={(value) => {
                                  changesubTestRange(
                                    value,
                                    index,
                                    indexSubTest
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    <Row>
                      <Col
                        xs={12}
                        className="eachrow"
                        // style={{ paddingLeft: "22px" }}
                      >
                        <Row>
                          <Textarea
                            key={"description_" + index}
                            name="description"
                            type="text"
                            label={"Description"}
                            onChange={(value) => {
                              var event = {
                                nativeEvent: {
                                  target: { name: "description" },
                                },
                              };
                              changeFieldsInLabInvestigations(
                                value.nativeEvent.target.value,
                                event,
                                index
                              );
                            }}
                            value={test.description}
                            rows={5}
                            style={{ width: "100%" }}
                            maxRows={5}
                            id={"desc" + index}
                            className="md-cell md-cell--bottom container-description-text-field "
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
}
export default LabtestUpdate;
