import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput, Button, Container, Grid, Loader } from "@mantine/core";
import LoginService from "../services/LoginService";
import StorageService from "../services/StorageService";
import profile from "../assets/profile.png";
import { LoginPayload } from "../model/Login";
import "../style/index.css";
import { authCheck } from "../App";
const storageService = new StorageService();
const loginService = new LoginService();

function Login() {
  const [phoneNo, setPhoneNo] = React.useState<string>("");
  const [countryCode, setCountryCode] = React.useState<string>("91");
  const [otp, setOtp] = React.useState<string>("");
  const [showOtp, setShowOtp] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);

  const [errorFlag, setErrorFlag] = React.useState<boolean>(false);
  const [errorFlagPhone, setErrorFlagPhone] = React.useState<boolean>(false);

  let navigate = useNavigate();

  useEffect(() => {
    authCheck().then((e) => {
      if (e) {
        navigate("/");
      }
    });
  }, []);
  function startLogin() {
    let onResponse = (data: any) => {
      setShowOtp(true);
      setLoading(false);
    };
    let onError = (error: any) => {
      setLoading(false);
    };

    if (phoneNo && phoneNo.length !== 10) {
      setErrorFlagPhone(true);
      setLoading(false);
    } else {
      setErrorFlagPhone(false);
      loginService.getOtp(phoneNo, countryCode, onResponse, onError);
    }
  }

  const validateOtp = () => {
    let requestBody: LoginPayload = {
      countryCode: parseInt(countryCode),
      phoneNo: parseInt(phoneNo),
      otp: parseInt(otp),
    };

    let onResponse = (data: any) => {
      console.log("datata", data);
      if (data && data.accessToken !== undefined && data.accessToken !== "") {
        storageService.set("token", data.accessToken);
        storageService.set("phone_no", phoneNo);
        storageService.set("countryCode", countryCode);
        setTimeout(() => {
          // navigate("/");
          setLoading(false);
          window.location.href = "/";
        }, 2000);
      } else {
        setErrorFlag(true);
        setLoading(false);
        setTimeout(() => {
          setErrorFlag(false);
        }, 4000);
      }
    };
    let onError = (error: any) => {
      setLoading(false);
    };
    loginService.validateOtp(requestBody, onResponse, onError);
  };
  return (
    <Container className="main-container">
      <Grid className="center-div">
        <Grid.Col span={10} style={{ textAlign: "center" }}>
          <img className="login-page-img" src={profile}></img>
        </Grid.Col>
      </Grid>
      <Grid className="center-div">
        <Grid.Col span={10}>
          <TextInput
            icon={<div>{countryCode}</div>}
            label="Phone No"
            value={phoneNo}
            type="number"
            onChange={(val) => {
              let phn = val.target.value;
              setPhoneNo(phn);
            }}
            error={errorFlagPhone ? "Check mobile number" : ""}
          />
        </Grid.Col>
      </Grid>
      {showOtp && (
        <Grid className="center-div">
          <Grid.Col span={10}>
            <TextInput
              label="Otp"
              type="number"
              value={otp}
              onChange={(val) => {
                setOtp(val.target.value);
              }}
              error={errorFlag ? "Invalid Otp" : ""}
              // errorProps={ "Error in Otp"}
            />
          </Grid.Col>
        </Grid>
      )}
      {loading && (
        <Grid className="center-div">
          <Grid.Col
            span={8}
            style={{
              textAlignLast: "center",
            }}
          >
            <Loader />
          </Grid.Col>
        </Grid>
      )}

      <Grid className="center-div">
        <Grid.Col
          span={8}
          style={{
            textAlignLast: "center",
          }}
        >
          <Button
            className="btn-color"
            onClick={() => {
              setLoading(true);
              showOtp ? validateOtp() : startLogin();
            }}
          >
            {showOtp ? "Login" : "Proceed"}
          </Button>{" "}
        </Grid.Col>
      </Grid>
    </Container>
  );
}
export default Login;
