import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid } from "@mantine/core";

import "../style/index.css";

function Logout() {
  let navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, []);

  return (
    <Container className="main-container">
      <Grid className="center-div">
        <Grid.Col span={10}></Grid.Col>
      </Grid>
      <Grid className="center-div">
        <Grid.Col span={10}> Clearing the data ...</Grid.Col>
      </Grid>
    </Container>
  );
}
export default Logout;
