import ApiService from "./MainServices";
import StorageService from "./StorageService";


 export const baseApi = process.env.REACT_APP_BASE_API;
 export const serviceApi = process.env.REACT_APP_SERVICE_API;

const storageService = new StorageService();
const apiService = new ApiService();

export const API = {
  getOtp: serviceApi + "/v1/transcript/otp",
  verifyOtp: serviceApi + "/v1/transcript/otp",
  searchPatient: serviceApi + "/v1/transcript/search",
  createLab: serviceApi + "/v1/transcript/create/{0}",
  updateLab: serviceApi + "/v1/transcript/update/{0}",
  getLatest: serviceApi + "/v1/transcript/latest/{0}",
  datatables: serviceApi + "/v1/transcript/reflist",
  labCodes: serviceApi + "/v1/transcript/search/lt",
  subLabCodes: serviceApi + "/v1/transcript/search/{0}?query={1}",
  loinc: serviceApi + "/v1/transcript/lt/{0}/st/{1}",
  getDocumentId: serviceApi + "/v1/transcript/digitisation/{0}",
  getAllTracking: serviceApi + "/v1/transcript/tracking/{0}/conditions"
};

const header = {
  "Content-Type": "application/json",
  auth_token: storageService.get("token"),
  phone_no: storageService.get("phone_no"),
};


class LabtestService {
  constructor() { }
  searchPatient(
    status: string,
    patientId: string,
    onResponse: any,
    onError1: any
  ) {
    let url = API.searchPatient + "?id=" + patientId;
    apiService.get(url, header, onResponse, onError1);
  }
  getPatient(patientId: string, onResponse: any, onError1: any) {
    let url = stringFormat(API.getLatest, patientId);
    // serviceApi + "/api/v1/tracking/li/" + patientId + "/latest/tracking";
    apiService.get(url, header, onResponse, onError1);
  }

  getDataTables(onResponse: any, onError1: any) {
    let url = API.datatables;
    apiService.get(url, header, onResponse, onError1);
  }

  getDocumentId(patientId: any, onResponse: any, onError: any) {
    let url = stringFormat(API.getDocumentId, patientId);
    apiService.get(url, header, onResponse, onError);
  }

  getAllTracking (pid: any, payload: any, onResponse: any, onError: any){
    let url = stringFormat(API.getAllTracking, pid)
    apiService.post(url, header, payload, onResponse, onError)
  }

  getLabTest(text: string, onResponse: any, onError: any) {
    let url = API.labCodes + "?query=" + text;
    // stringFormat(detailedAssessment.getLabTest, text);

    return apiService.get(
      url,
      header,
      (response: any) => {
        if (response) {
          onResponse(response);
        } else {
          onResponse([]);
        }
      },
      (error: any) => {
        onResponse([]);
      }
    );
  }

  getSubTest(LabtestId: string, text: string, onResponse: any, onError: any) {
    let url = stringFormat(API.subLabCodes, LabtestId, text);

    return apiService.get(
      url,
      header,
      (response: any) => {
        if (response) {
          onResponse(response);
        } else {
          onResponse([]);
        }
      },
      (error: any) => {
        onResponse([]);
      }
    );
  }
  getSubTestData(
    LabtestId: string,
    SubLabTestId: string,
    onResponse: any,
    onError: any
  ) {
    let url = stringFormat(API.loinc, LabtestId, SubLabTestId);

    return apiService.get(url, header, onResponse, onError);
  }

  addLabTest(payload: any, patientid: string, onResponse: any, onError: any) {
    // let url = APIConfig.tracking.labtest.add.stringFormat(patientid);
    let url = stringFormat(API.createLab, patientid);

    apiService.post(url, header, payload, onResponse, onError);
  }

  updateLabTest(
    payload: string,
    patientid: string,
    trackingId: string,
    onResponse: any,
    onError: any
  ) {
    let url = stringFormat(API.updateLab, patientid, trackingId);

    apiService.put(url, header, payload, onResponse, onError);
  }
}

export default LabtestService;

function stringFormat(template: string, ...args: any[]) {
  return template.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
}
