import React from "react";
import success from "../assets/success.png";
import { Row, Col } from "react-flexbox-grid";
import {
  Modal,
  Autocomplete,
  Button,
  Card,
  Select,
  Radio,
  TextInput,
  Textarea,
  Menu,
} from "@mantine/core";
import { IconCircleMinus, IconMinus, IconPlus } from "@tabler/icons";
import "../style/LabtestTracking.css";
import LabtestService from "../services/LabtestService";
import { showNotification } from "@mantine/notifications";
import ChooseScreen from "./ChooseScreen";

class LabtestUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.labtestService = new LabtestService();
    this.state = {
      status: "new",
      screen: "1",
      successFlag: false,
      editFlag: false,
      rerenderFlag: true,
      dataTables: props.dataTables || [],
      range: props.dataTables.LAB_TEST_RANGE || [],
      evidence: props.dataTables.LAB_TEST_REPORT_EVIDENCE || [],
      subTestRange: props.dataTables.SUB_LAB_TEST_RANGE || [],
      testUOM: props.dataTables.LAB_TEST_UOM,
      patientId: props.patientId || null,
      visible: false,
      medication: [],
      addMedicine: false,
      labTestData: [],
      SublabTestData: [],
      labInvestigations: null, // this.setLabInvestigation(this.props),
    };
    // console.log("sdsf",thi)
  }
  setLabInvestigation(props) {
    let labInvestigations = {};
    if (props.labtest) {
      if (props.labtest.labtest !== null && props.labtest.review) {
        props.labtest.labtest.map((labtest) => {
          if (labtest.subTest.length === 0) {
            labtest.subTest.push({
              subTest: null,
              loincCode: null,
              value: null,
              valueUOM: null,
              range: null,
            });
          }
        });

        labInvestigations = props.labtest;
      } else {
        labInvestigations = {
          patientId: null,
          createdBy: null,
          createdByName: null,
          review: null,
          labtest: [
            {
              dateCreated: null,
              //   createdBy: null,
              //   createdByName: null,
              labTest: null,
              labTestCode: {
                label: "",
                value: "",
              },
              dayAssessment: null,
              testConductedDate: null,
              monthAssessment: null,
              yearAssessment: null,
              evidenceOfReports: null,
              allSubTestAssessed: null,
              range: null,
              subTest: [
                {
                  subTest: null,
                  subTestCode: { label: "", value: "" },
                  loincCode: null,
                  value: null,
                  valueUOM: null,
                  range: null,
                },
              ],
              description: null,
            },
          ],
        };
      }
    }
    return labInvestigations;
  }
  // hide = () => {
  //   this.setState({ visiblelab: false });
  // };

  fire(text, index) {
    let labInvestigations = this.state.labInvestigations;

    let str = text.substring(0, 4);

    if (str === "LABT") {
      const { labTestData } = this.state;
      let item = labTestData.find((e) => e.value === text);

      if (item !== undefined) {
        labInvestigations.labtest[index].labTest = text;
        labInvestigations.labtest[index].labTestCode = {
          label: item.label,
          value: text,
        };
        // this.OnSelect(text, index);
      }

      this.setState({ labInvestigations }, () => {});
    } else {
      labInvestigations.labtest[index].labTestCode = { label: text, value: "" };
      this.setState({ labInvestigations }, () => {
        // this.setState({ rerenderFlag: true });
        if (text.length > 1) {
          this.getLabTest(text);
        }
      });
    }
    //
  }

  getLabTest = (text) => {
    let onResponse = (data) => {
      if (data) {
        this.setState({
          labTestData: data,
        });
      }
    };
    let onError = (error) => {};

    this.labtestService.getLabTest(text, onResponse, onError);
  };

  changeSelectFieldInEvidenceLab = (value, index) => {
    let labInvestigations = this.state.labInvestigations;
    let lab = labInvestigations.labtest[index];
    lab.evidenceOfReports = value;

    this.setState({
      labInvestigations,
      editFlag: true,
    });
  };
  removeLabtestCard = (index) => {
    var labInvestigations = this.state.labInvestigations; //labtest;
    if (labInvestigations.labtest.length > 1) {
      labInvestigations.labtest.splice(index, 1);

      this.setState({ labInvestigations, rerenderFlag: false }, () => {
        this.setState({
          rerenderFlag: true,
          editFlag: true,
        });
      });
    } else {
      showNotification({
        title: "Error removing!",
        message: "Minimum 1 lab test required 🤥",
        color: "red",
      });
    }
  };
  addMore = () => {
    let labInvestigation = this.state.labInvestigations.labtest;
    labInvestigation.unshift({
      //   dateCreated: null,
      //   createdBy: null,
      //   createdByName: null,
      labTest: null,
      labTestCode: {
        label: "",
        value: "",
      },
      dayAssessment: null,
      testConductedDate: null,
      monthAssessment: null,
      yearAssessment: null,
      evidenceOfReports: null,
      allSubTestAssessed: null,
      range: null,
      subTest: [
        {
          subTestCode: { label: "", value: "" },
          subTest: null,
          loincCode: null,
          value: null,
          valueUOM: null,
          range: null,
        },
      ],
      description: null,
    });
    this.setState({
      labInvestigation,
      editFlag: true,
    });
    this.setState(
      {
        rerenderFlag: false,
      },
      () => {
        this.setState({
          rerenderFlag: true,
        });
      }
    );
  };

  addMoreSubTest = (index) => {
    let labInvestigations = this.state.labInvestigations;
    labInvestigations.labtest[index].subTest.push({
      subTestCode: { label: "", value: "" },
      subTest: null,
      loincCode: null,
      value: null,
      valueUOM: null,
      range: null,
    });
    this.setState({
      labInvestigations,
      editFlag: true,
    });
  };
  deleteSubTest = (index, indexSubTest) => {
    let labInvestigations = this.state.labInvestigations;
    if (labInvestigations.labtest[index].subTest.length > 1) {
      labInvestigations.labtest[index].subTest.splice(indexSubTest, 1);
      this.setState({
        labInvestigations,
        editFlag: true,
      });
    } else {
      showNotification({
        title: "Error removing!",
        message: "Minimum 1 sub test required 🤥",
        color: "red",
      });
    }
  };

  addSubTestButton(index, indexSubTest) {
    return (
      <div className="subtest-buttons">
        <IconCircleMinus
          className="remove-sub-labtest"
          style={{ top: "1rem", padding: "3px" }}
          icon
          primary
          onClick={() => this.deleteSubTest(index, indexSubTest)}
        />
        <IconPlus
          className="add-sub-labtest"
          style={{ top: "1rem" }}
          icon
          primary
          swapTheming
          onClick={() => this.addMoreSubTest(index)}
        />
      </div>
    );
  }

  fireSub(text, index, subIndex, labTest) {
    let labInvestigations = this.state.labInvestigations;

    let str = text.substring(0, 5);

    if (str === "SLABT") {
      const { SublabTestData } = this.state;
      let item = SublabTestData.find((e) => e.value === text);

      if (item !== undefined) {
        labInvestigations.labtest[index].subTest[subIndex].subTest = text;
        labInvestigations.labtest[index].subTest[subIndex].subTestCode = {
          label: item.label,
          value: text,
        };
        this.onSubtestSelect(labTest, text, index, subIndex);
      }

      this.setState({ labInvestigations }, () => {});
    } else {
      labInvestigations.labtest[index].subTest[subIndex].subTestCode = {
        label: text,
        value: "",
      };
      this.setState({ labInvestigations }, () => {
        // this.setState({ rerenderFlag: true });
        if (text.length > 1) {
          this.getLabTestSub(text, labTest);
        }
      });
    }
  }
  onSubtestSelect(labtest, subtest, index, subIndex) {
    let onResponse = (data) => {
      var event = { nativeEvent: { target: { name: "LOINCname" } } };
      this.changeSubTestFields(data.loincName, event, index, subIndex);
    };
    let onError = (error) => {};
    this.labtestService.getSubTestData(labtest, subtest, onResponse, onError);
  }
  getLabTestSub = (text, Id) => {
    let onResponse = (data) => {
      if (data)
        this.setState({
          SublabTestData: data,
        });
    };
    let onError = (error) => {};

    this.labtestService.getSubTest(Id, text, onResponse, onError);
  };
  changeFieldsInLabInvestigations = (value, proxy, index) => {
    let event = proxy.nativeEvent;
    let labInvestigations = this.state.labInvestigations;
    let lab = labInvestigations.labtest[index];
    // lab.dateCreated = new Date().getTime();
    // lab.createdBy = "67282"; //this.props.userProfile.id;
    // lab.createdByName = "nimit";
    // this.props.userProfile.employeeProfile.firstName +
    // " " +
    // this.props.userProfile.employeeProfile.lastName;
    switch (event.target.name) {
      case "labTestName":
        lab.labtestName = value;

        break;
      case "labTest":
        lab.labTest = value;

        break;
      case "description":
        lab.description = value;

        break;
      case "date1":
        lab.dayAssessment = value;

        break;
      case "month1":
        lab.monthAssessment = value;

        break;
      case "year1":
        lab.yearAssessment = parseInt(value);

        break;
      case "testConductedDate":
        lab.testConductedDate = value;

        break;
      default:
        break;
    }
    this.setState({ labInvestigations, editFlag: true });
  };

  subtestValue = (value, index, subTestindex) => {
    let labInvestigations = this.state.labInvestigations;
    let lab = labInvestigations.labtest[index].subTest[subTestindex];
    let labtest = labInvestigations.labtest[index];
    // labtest.dateCreated = new Date().getTime();
    // labtest.createdBy = "67282"; //this.props.userProfile.id;
    // labtest.createdByName = "e";
    // this.props.userProfile.employeeProfile.firstName +
    // " " +
    // this.props.userProfile.employeeProfile.lastName;
    lab.valueUOM = value;
    this.setState({
      labInvestigations,
      editFlag: true,
    });
  };

  assessmentofReport = (value, index) => {
    let labInvestigations = this.state.labInvestigations;
    let lab = labInvestigations.labtest[index];
    // lab.dateCreated = new Date().getTime();
    // lab.createdBy = "67282"; //this.props.userProfile.id;
    // lab.createdByName = "nimit";
    // this.props.userProfile.employeeProfile.firstName +
    // " " +
    // this.props.userProfile.employeeProfile.lastName;
    if (value) lab.allSubTestAssessed = true;
    else if (value === false) lab.allSubTestAssessed = false;
    else lab.allSubTestAssessed = null;
    this.setState({
      labInvestigations,
      editFlag: true,
    });
  };
  selectLabRange = (value, index) => {
    let labInvestigations = this.state.labInvestigations;
    let lab = labInvestigations.labtest[index];
    lab.range = value;
    // lab.dateCreated = new Date().getTime();
    // lab.createdBy = "67282"; //this.props.userProfile.id;
    // lab.createdByName = "nimit";
    // this.props.userProfile.employeeProfile.firstName +
    // " " +
    // this.props.userProfile.employeeProfile.lastName;

    this.setState({
      labInvestigations,
      editFlag: true,
    });
  };
  subTestRange = (value, index, subTestindex) => {
    let labInvestigations = this.state.labInvestigations;
    let lab = labInvestigations.labtest[index].subTest[subTestindex];
    let labtest = labInvestigations.labtest[index];
    // labtest.dateCreated = new Date().getTime();
    // labtest.createdBy = "67292"; //this.props.userProfile.id;
    // labtest.createdByName = "uih";
    // this.props.userProfile.employeeProfile.firstName +
    // " " +
    // this.props.userProfile.employeeProfile.lastName;
    lab.range = value;
    this.setState({
      labInvestigations,
      editFlag: true,
    });
  };
  changeSubTestFields = (value, proxy, index, indexSubTest) => {
    let event = proxy.nativeEvent;

    let labInvestigations = this.state.labInvestigations;
    let lab = labInvestigations.labtest[index].subTest[indexSubTest];
    let labtest = labInvestigations.labtest[index];

    // labtest.dateCreated = new Date().getTime();
    // labtest.createdBy = "67282"; //this.props.userProfile.id;
    // labtest.createdByName = "erf";
    // this.props.userProfile.employeeProfile.firstName +
    // " " +
    // this.props.userProfile.employeeProfile.lastName;
    switch (event.target.name) {
      case "subTestName":
        lab.subTestName = value;

        break;
      case "subTest":
        lab.subTest = value;

        break;
      case "LOINCname":
        lab.loincCode = value;

        break;
      case "value":
        lab.value = value;

        break;
      default:
        break;
    }
    this.setState({
      labInvestigations,
      editFlag: true,
    });
  };
  testConduct(value) {
    if (value) {
      var year = new Date(value).getFullYear().toString();
      var month = (new Date(value).getMonth() + 1).toString();
      if (month.length === 1) month = "0" + month;
      var date = new Date(value).getDate().toString();
      if (date.length === 1) date = "0" + date;

      return year + "-" + month + "-" + date;
    }
  }

  select = (val) => {
    console.log("ssss", val);
    this.setState({
      screen: "2",
      status: val,
    });
    if (val === "review") {
      this.searchPatientLabtest();
    }
  };
  searchPatientLabtest = () => {
    let onResponse = (data) => {
      //   setLabInvestigation(data);
      console.log("akakakak", data);
      if (data.labtest !== undefined) {
        this.setState({
          labInvestigations: data,
        });
      }
    };
    let onError = () => {};
    const { patientId } = this.state;
    this.labtestService.getPatient(patientId, onResponse, onError);
  };
  render() {
    const {
      status,
      labInvestigations,
      labTestData,
      rerenderFlag,
      range,
      SublabTestData,
      successFlag,
      screen,
    } = this.state;
    console.log("labtest inev", this.state);
    return (
      <Modal
        opened={true}
        // onClose={() => this.props.close()}
        className="labtest-container"
        title={
          !successFlag && (
            <div className="title-dialog">
              <div>
                <h3 style={{ margin: "0" }}>
                  {/* {"Lab Investigations (" + labInvestigations &&
                    labInvestigations.labtest.length + ")"} */}
                </h3>
              </div>
              <div>
                {/* <Button
                  onClick={() => {
                    this.addMore();
                  }}
                  raised
                  primary
                  className="add-labtest"
                >
                  + Add New
                </Button>

                <Button
                  onClick={() => {
                    this.addMore();
                  }}
                  raised
                  primary
                  className="add-labtest"
                  onClick={() => {
                    this.selectSave(true);
                  }}
                  style={{ background: "#ef7c01" }}
                >
                  Save
                </Button> */}

                {/* <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Button style={{ background: "#ef7c01" }}>Save</Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item>
                      <label
                        onClick={() => {
                          this.selectSave(false);
                        }}
                      >
                        Save
                      </label>{" "}
                    </Menu.Item>
                    <Menu.Item>
                      <label
                        onClick={() => {
                          this.selectSave(true);
                        }}
                      >
                        Save For Review
                      </label>{" "}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu> */}
              </div>
            </div>
          )
        }
      >
        <div id="Lab Investigations">
          {/* {successFlag && screen === "" && (
            <Col xs={12} sm={12} md={12} lg={12}>
              <Row className="center-success">
                <img className="image-labtest-success" src={success} />
              </Row>
              <Row className="center-success">
                <h2>Entry created successfully</h2>
              </Row>
            </Col>
          )} */}
          {/* {!successFlag && screen === "1" && (
            <ChooseScreen select={() => this.select} />
          )} */}
          {!successFlag &&
            screen === "2" &&
            labInvestigations &&
            labInvestigations.labtest.map((test, index) => (
              <div key={index + "LabInv"}>
                <Card
                  key={index + "labtest"}
                  className="md-block-centered container-card-lab-test"
                >
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Row>
                      {/* <Col xs={5} className="eachrow">
                        <Autocomplete
                          withAsterisk
                          label="Lab Test"
                          placeholder="Type here..."
                          data={labTestData}
                          onChange={(e) => {
                            this.fire(e, index);
                          }}
                          value={test.labTestCode.label}
                          filter={(value, item) =>
                            item.label
                              .toLowerCase()
                              .includes(value.toLowerCase().trim())
                          }
                        />
                      </Col> */}
                      {/* <Col xs={3} className="eachrow">
                        <Row className="labelHeading ">
                          Date <label style={{ color: "#fa5252" }}>*</label>{" "}
                        </Row>
                        <Row>
                          {rerenderFlag && (
                            <input
                              style={{ border: "none" }}
                              type="date"
                              id={"testConductedDate" + index}
                              name="testConductedDate"
                              value={this.testConduct(test.testConductedDate)}
                              onChange={() => {
                                var dateControl = document.getElementById(
                                  "testConductedDate" + index
                                ).value;

                                var event = {
                                  nativeEvent: {
                                    target: { name: "testConductedDate" },
                                  },
                                };
                                this.changeFieldsInLabInvestigations(
                                  new Date(
                                    new Date(dateControl).getFullYear(),
                                    new Date(dateControl).getMonth(),
                                    new Date(dateControl).getDate()
                                  ).getTime(),
                                  event,
                                  index
                                );
                              }}
                            />
                          )}
                        </Row>
                      </Col> */}

                      <Col xs={3} className="eachrow">
                        {/* <Row className="labelHeading ">Evidence</Row> */}
                        <Row>
                          {/* <Select
                            label="Evidence"
                            id={"evidence" + index}
                            className="dropdown-dashboard"
                            data={this.state.evidence}
                            onChange={(value) => {
                              this.changeSelectFieldInEvidenceLab(value, index);
                            }}
                            name="evidence"
                            value={test.evidenceOfReports}
                          /> */}
                        </Row>
                      </Col>
                      <Col xs={1}>
                        <Row>
                          {/* <IconMinus
                            style={{
                              position: "relative",
                              right: "-2rem",
                            }}
                            onClick={() => {
                              this.removeLabtestCard(index);
                            }}
                          /> */}
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={5} className="eachrow row-2">
                        <Row className="labelHeading leftSpace">
                          Allsub tests assessed{" "}
                        </Row>
                        {/* <Row
                          id={"subTestAssessed" + index}
                          className="each-row-2"
                        >
                          {[
                            {
                              label: "Yes",
                              value: true,
                            },
                            {
                              label: "No",
                              value: false,
                            },
                            { label: "None", value: null },
                          ].map((itm) => (
                            <Radio
                              className="each-radio"
                              name={"subTestAssessed_" + index}
                              checked={itm.value === test.allSubTestAssessed}
                              label={itm.label}
                              onClick={() => {
                                console.log("allevifididi", itm.value);
                                this.assessmentofReport(itm.value, index);
                              }}
                            />
                          ))}
                        </Row> */}
                      </Col>
                      <Col xs={7} className="eachrow">
                        <Row className="labelHeading ">Range</Row>
                        {/* <Row className="each-row-2">
                          {range !== undefined &&
                            range &&
                            range.map((itm) => (
                              <Radio
                                className="each-radio"
                                checked={itm.value === test.range}
                                label={itm.label}
                                onClick={() => {
                                  this.selectLabRange(itm.value, index);
                                }}
                              />
                            ))}
                        </Row> */}
                      </Col>
                    </Row>

                    {/* ..........;;;;;;;;;; */}
                    {test.subTest.map((subTest, indexSubTest) => {
                      return (
                        <div key={indexSubTest + "subtest"}>
                          <Row>
                            <Col
                              xs={5}
                              className="eachrow"
                              style={{ display: "flex" }}
                            >
                              {/* <Autocomplete
                                withAsterisk
                                label="Sub Lab Test"
                                placeholder="Type here..."
                                data={SublabTestData}
                                onChange={(e) => {
                                  this.fireSub(
                                    e,
                                    index,
                                    indexSubTest,
                                    test.labTest
                                  );
                                }}
                                value={subTest.subTestCode.label}
                                filter={(value, item) =>
                                  item.label
                                    .toLowerCase()
                                    .includes(value.toLowerCase().trim())
                                }
                              /> */}

                              {/* {this.addSubTestButton(index, indexSubTest)} */}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              {/* <TextInput
                                label="Loinc Name"
                                name="LOINCname"
                                type="text"
                                id={"loincname" + indexSubTest + index}
                                onChange={(value) => {
                                  var event = {
                                    nativeEvent: {
                                      target: { name: "LOINCname" },
                                    },
                                  };
                                  this.changeSubTestFields(
                                    value.target.value,
                                    event,
                                    index,
                                    indexSubTest
                                  );
                                }}
                                value={subTest.loincCode}
                              /> */}
                            </Col>
                            <Col xs={2}>
                              {/* <TextInput
                                label="Value"
                                name="value"
                                type="number"
                                id={"value" + indexSubTest + index}
                                onChange={(value) => {
                                  // if (value.length < 11) {
                                  var event = {
                                    nativeEvent: {
                                      target: { name: "value" },
                                    },
                                  };
                                  this.changeSubTestFields(
                                    value.target.value,
                                    event,
                                    index,
                                    indexSubTest
                                  );
                                  // }
                                }}
                                value={subTest.value}
                              /> */}
                            </Col>
                            <Col xs={2}>
                              {/* <Select
                                label="Unit"
                                // style={{ width: "70%" }}
                                value={subTest.valueUOM}
                                className="dropdown-dashboard"
                                data={this.state.testUOM}
                                id={"valueUom" + indexSubTest + index}
                                onChange={(value, e) =>
                                  this.subtestValue(value, index, indexSubTest)
                                }
                              /> */}
                            </Col>
                            <Col xs={2}>
                              {/* <Select
                                label="Range"
                                style={{ width: "70%" }}
                                value={subTest.range}
                                className="dropdown-dashboard"
                                data={this.state.subTestRange}
                                name="range-subtest"
                                id={"range-subtest" + indexSubTest + index}
                                onChange={(value, e) =>
                                  this.subTestRange(value, index, indexSubTest)
                                }
                              /> */}
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    <Row>
                      <Col
                        xs={12}
                        className="eachrow"
                        // style={{ paddingLeft: "22px" }}
                      >
                        <Row>
                          {/* {rerenderFlag && (
                            <Textarea
                              name="description"
                              type="text"
                              label={"Description"}
                              onChange={(value) => {
                                var event = {
                                  nativeEvent: {
                                    target: { name: "description" },
                                  },
                                };
                                this.changeFieldsInLabInvestigations(
                                  value.nativeEvent.target.value,
                                  event,
                                  index
                                );
                              }}
                              value={test.description}
                              rows={5}
                              style={{ width: "100%" }}
                              maxRows={5}
                              id={"desc" + index}
                              className="md-cell md-cell--bottom container-description-text-field "
                            />
                          )} */}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </div>
            ))}
        </div>
      </Modal>
    );
  }
  selectSave(review) {
    this.setState({ editFlag: false });
    var lab = this.state.labInvestigations;

    let errorFlag = false;
    console.log("dfgrrdfearfehfneriu", lab.labtest);
    lab.labtest.map((e) => {
      if (e.labTestCode.value === null || e.labTestCode.value === "") {
        errorFlag = true;
        showNotification({
          title: "Error in saving!",
          message: "Check lab test name",
          color: "red",
        });
        // this.labTestErrorFlag = true;
      } else {
        errorFlag = false;
        if (e.testConductedDate === null) {
          errorFlag = true;
          showNotification({
            title: "Error in saving!",
            message: "Check date ",
            color: "red",
          });
        } else {
          errorFlag = false;
          if (e.subTest.length > 0) {
            e.subTest.map((eSub) => {
              if (
                eSub.subTestCode.value === null ||
                eSub.subTestCode.value === ""
              ) {
                errorFlag = true;
                showNotification({
                  title: "Error in saving!",
                  message: "Check sub tests name ",
                  color: "red",
                });
              } else {
                errorFlag = false;
              }
            });
          } else {
            errorFlag = true;
            showNotification({
              title: "Error in saving!",
              message: "Check sub tests added ",
              color: "red",
            });
          }
        }
      }
    });
    console.log("yoyoyoy", errorFlag);
    setTimeout(() => {
      if (!errorFlag) {
        this.saveLab(lab, review);
      } else {
        showNotification({
          title: "Check Error!",
          message: "Check Error",
          color: "red",
        });
      }
    }, 2000);
  }
  saveLab = (labInvestigation, review) => {
    const { patientId } = this.state;

    labInvestigation.createdBy = 156054;
    labInvestigation.createdByName = "Vikrant Kumar";
    labInvestigation.createdBy = 156054;
    labInvestigation.patientId = parseInt(patientId);
    labInvestigation.review = review;
    labInvestigation.updatedBy = 156054;
    labInvestigation.updatedByName = "Vikrant Kumar";

    this.labtestService.addLabTest(
      labInvestigation,
      patientId,
      (data) => {
        this.setState({ successFlag: true }, () => {
          setTimeout(() => {
            this.props.close();
            this.setState({ success: false });
          }, 3000);
        });
      },
      (error) => {}
    );
  };
}
export default LabtestUpdate;
