import ApiService from "./MainServices";
import { LoginPayload } from "../model/Login";
import { API } from "./LabtestService";

const apiService = new ApiService();
const header = {
  "Content-Type": "application/json",
};

class LoginService {
  constructor() {}
  getOtp(phoneNo: string, countryCode: string, onResponse: any, onError1: any) {
    let url =
      API.getOtp + "?phoneNo=" + phoneNo + "&countryCode=" + countryCode;
    apiService.get(url, header, onResponse, onError1);
  }
  validateOtp(body: LoginPayload, onResponse: any, onError1: any) {
    let url = API.verifyOtp;
    apiService.post(url, header, body, onResponse, onError1);
  }
}

export default LoginService;
